@import "/src/styles/mixin";

.wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  height: auto;

  @media screen and (max-width: 767px) {
    gap: 0px 0px;
  }
}

.cardsList {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  height: auto;
  overflow: hidden;
  // position: relative;
  // display: grid;
  // gap: 12px;
  // width: 100%;
  // height: 100%;
  // grid-template-columns: repeat(auto-fill, minmax(250px, auto));
  // grid-auto-flow: dense;

  // @media screen and (max-width: 1656px) {
  //   grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
  //   gap: 6px;
  // }

  // @media screen and (max-width: 1470px) {
  //   grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  //   gap: 6px;
  // }

  // @media screen and (max-width: 1200px) {
  //   grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  //   gap: 6px;
  // }

  // @media screen and (max-width: 767px) {
  //   grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  //   margin-bottom: 20px;
  // }

  // @media screen and (max-width: 500px) {
  //   grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
  // }

  // @media screen and (max-width: 425px) {
  //   display: flex;
  //   flex-wrap: wrap;
  // }
  // @media screen and (max-width: 375px) {
  //   grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  // }

  // @media screen and (max-width: 320px) {
  //   grid-template-columns: repeat(auto-fill, minmax(91px, 1fr));
  // }
}

.link {
  margin: 24px auto 0;
  padding: 8px 16px;
  border: 1px solid var(--primary-color);
  border-radius: var(--border-radius-small);
  background-color: var(--white-color);
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  z-index: 20;

  @media screen and (max-width: 767px) {
    margin-bottom: 20px;
    // position: absolute;
    // bottom: 164px;
    // left: 50%;
    // transform: translateX(-50%);
  }
}

.text {
  @include font14;
  grid-area: 1 / 1 / 2 / 5;
  text-align: center;
}

.icon {
  width: 7px;
  transform: rotate(180deg);

  path {
    stroke: var(--black-color);
  }
}

.card {
  width: 100%;
  height: fit-content;

  &:nth-child(n + 5) {
    display: none;
  }
}

@media screen and (max-width: 1439.98px) {
  .card {
    width: 100%;

    &:nth-child(n + 5) {
      display: none;
    }
  }
}

@media screen and (max-width: 1365.98px) {
  .card {
    width: 100%;

    &:nth-child(n + 3) {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .card {
    width: 100%;

    &:nth-child(n + 0) {
      display: block;
    }
  }

  @media screen and (max-width: 425px) {
    .card {
      width: 48%;

      &:nth-child(n + 0) {
        display: block;
      }
    }
  }
}

@import "/src/styles/mixin";

.wrap {
  position: sticky;
  top: 0;
  z-index: 4;

  .marquee {
    width: 100%;
    background-color: var(--secondary-color);
    z-index: 0;
    padding: 10px 0;
    gap: 20px;

    @media screen and (max-width: 767px) {
      padding: 8px 0;
    }

    .text {
      @include font14;
      color: var(--primary-color);
    }
  }
}

.picture {
  display: flex;
  height: 100%;
}

.link {
  display: block;
}

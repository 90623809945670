@import "/src/styles/mixin";

.button {
  @include font14;
  display: flex;
  gap: 8px;
  padding: 12px 0;
  font-weight: 700;
}

.count {
  padding: 0 6px;
  border-radius: 2px;
  background-color: var(--primary-color);
  font-size: 11px;
}

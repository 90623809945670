.wrap {
  position: absolute;
  top: calc(100% + 12px);
  left: 0;
  display: flex;
  background-color: var(--white-color);
  padding: 24px 46px;
  width: 100%;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.08);
  z-index: 3;
  overflow: auto;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: fit-content;
    z-index: 2;

    .head {
      display: flex;
      gap: 16px;
      flex-direction: column;
      border-bottom: 1px solid var(--grey-lines-color);
      padding-bottom: 20px;
    }
  }

  @media screen and (max-height: 823px) {
    bottom: calc(-100svh + 130px);
  }

  @media screen and (max-width: 1256px) and (min-width: 768px) {
    width: auto;
    top: calc(100% + 10px);
    left: -180px;
    right: -180px;
  }

  @media screen and (max-width: 767px) {
    width: auto;
    top: calc(100% + 10px);
    left: -44px;
    right: -44px;
    padding: 8px 8px;
  }

  @media screen and (max-width: 767px) {
    height: calc(100dvh - 60px);
    padding: 8px 8px 46px 8px;
    box-shadow:
      32px 0px 0px 0px rgba(255, 255, 255, 1),
      -32px 0px 0px 0px rgba(255, 255, 255, 1);
  }
}

@import "/src/styles/mixin";

.menu {
  display: none;
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: block;
  }
}

.button {
  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.header {
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 40;
  background-color: var(--white-color);

  &Top {
    &Wrap {
      position: relative;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      @media only screen and (min-width: 320px) and (max-width: 767.98px) {
        display: none;
      }
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding-bottom: 8px;
  }

  @media screen and (max-width: 768px) {
    z-index: 40;
  }

  .container {
    padding-top: 10px;

    @media only screen and (min-width: 320px) and (max-width: 767.98px) {
      padding-top: 8px;
    }
  }
}

.bottom {
  &Wrap {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 52;
    @media screen and (max-width: 767px) {
      gap: 16px;
    }

    .close_search {
      width: 24px;
      height: 24px;
    }

    @media screen and (max-width: 1024px) {
      position: static;
    }
  }
}

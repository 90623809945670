@import "/src/styles/mixin";

.wrap {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  padding: 8px 16px 100px;
  overflow-x: hidden;
  background-color: var(--white-color);
  z-index: 3;
}

.list {
  &:first-child {
    border-bottom: 1px solid var(--black-color);
  }
}

.item {
  &:not(:last-child) {
    border-bottom: 1px solid var(--grey-lines-color);
  }

  .button {
    @include font14;
    display: flex;
    gap: 8px;
    padding: 12px 0;
    font-weight: 700;
    width: 100%;
  }

  button,
  a,
  p {
    width: 100%;
  }
}

@import "/src/styles/mixin";

.wrap {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 36px;
  flex: 1 1 auto;
  border: 2px solid var(--primary-color);
  border-radius: var(--border-radius-small);
  padding-right: 20px;

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    margin-right: 0;
    padding-right: 12px;
  }
}

.input {
  @include font14;
  width: 100%;
  font-weight: 600;
  padding: 11px 20px;

  @media only screen and (min-width: 320px) and (max-width: 767.98px) {
    padding: 11px 18px;
  }
}

.searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
}

.voice {
  margin-right: 8px;
}

.active_mic {
  width: 24px;
  height: 24px;
}
